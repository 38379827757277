// core version + navigation, pagination modules:
import Swiper, { Navigation } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

(function() {
    const swiper = new Swiper('#events-carousel', {
        // configure Swiper to use modules
        modules: [Navigation],
        spaceBetween: 40,
        centeredSlides: true,
        navigation: {
            nextEl: '#events-controller .next',
            prevEl: '#events-controller .prev',
        }
    });
})();
